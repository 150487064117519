<template>
  <div class="row text-center">
    <div class="row justify-content-center">
      <h1>Ân lịch: {{ this.dateLib.getFullDateLunar() }}</h1>
      <h3>Dương lịch {{ this.dateLib.getFullDateSun() }}</h3>

      <h3>
        {{ this.dateLib.getDayStr() }} / ngày {{ this.dateLib.getDateStr() }} /
        tháng {{ this.dateLib.getMonthStr() }} / năm
        {{ this.dateLib.getYearStr() }}
      </h3>
      <br />
      <h5>Giờ tốt: {{ this.dateLib.getLuckHour() }}</h5>
      <br />
    </div>

    <div class="row justify-content-center">
      <div class="col-6">
        <Calendar
          class="custom-calendar max-w-full"
          :masks="masks"
          disable-page-swipe
          is-expanded
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Calendar } from "v-calendar";
import dateLib from "../libs/dateLib";

export default {
  data: () => ({
    masks: {
      weekdays: "WWW",
    },
    datelib: Object,
  }),
  components: {
    Calendar,
  },
  created() {
    this.dateLib = dateLib;
    this.dateLib.get();
    // console.log(this.dateLib.get());
  },
  methods: {
    calendaClick(e) {
      console.log(e.Calendar);
      alert("Calenda Click");
    },
  },
};
</script>

<style lang="postcss" scoped>
::-webkit-scrollbar {
  width: 0px;
}
::-webkit-scrollbar-track {
  display: none;
}
/deep/ .custom-calendar.vc-container {
  --day-border: 1px solid #b8c2cc;
  --day-border-highlight: 1px solid #b8c2cc;
  --day-width: 90px;
  --day-height: 90px;
  --weekday-bg: #f8fafc;
  --weekday-border: 1px solid #eaeaea;
  border-radius: 0;
  width: 100%;
  & .vc-header {
    background-color: #f1f5f8;
    padding: 10px 0;
  }
  & .vc-weeks {
    padding: 0;
  }
  & .vc-weekday {
    background-color: var(--weekday-bg);
    border-bottom: var(--weekday-border);
    border-top: var(--weekday-border);
    padding: 5px 0;
  }
  & .vc-day {
    padding: 0 5px 3px 5px;
    text-align: left;
    height: var(--day-height);
    min-width: var(--day-width);
    background-color: white;
    &.weekday-1,
    &.weekday-7 {
      background-color: #eff8ff;
    }
    &:not(.on-bottom) {
      border-bottom: var(--day-border);
      &.weekday-1 {
        border-bottom: var(--day-border-highlight);
      }
    }
    &:not(.on-right) {
      border-right: var(--day-border);
    }
  }
  & .vc-day-dots {
    margin-bottom: 5px;
  }
}
</style>
