import { Lunar } from 'lunar-calendar-ts-vi';

class dateLibCls {
    get(date) {
        const lunar = new Lunar();
        if (!date)
            // Get today
            return this.lunarDate = lunar.getBlockLunarToday();
        else
            //get data by valid date
            return this.lunarDate = lunar.getBlockLunarDate(new Date(date));
    }
    getAll() { return this.lunarDate }
    getHour() { return this.lunarDate.zodiacHour }
    getDay() { return this.lunarDate.lunarDate }
    getDayStr() { return this.lunarDate.solarDayStr }
    getDateStr() { return this.lunarDate.lunarDateStr }
    getMonth() { return this.lunarDate.lunarMonth }
    getMonthStr() { return this.lunarDate.lunarMonthStr }
    getYear() { return this.lunarDate.lunarYear }
    getYearStr() { return this.lunarDate.lunarYearStr }
    getDayofWeek() { return this.lunarDate.solarDayStr }
    getLuckHour() { return this.lunarDate.zodiacHour }
    getFullDateLunar() {
        return `${this.getDay()}/${this.getMonth()}/${this.getYear()}`
    }
    getFullDateSun() {
        let today = new Date();
        return `${String(today.getDate()).padStart(2, '0')}/${String(today.getMonth() + 1).padStart(2, '0')}/${today.getFullYear()}`
    }

}
//Tinh toan ngay am lich

const dateLib = new dateLibCls();

export default dateLib;